<template>
    <div class="container md-layout md-alignment-top-center">
        <form v-if="mode === MODES.FORM"
              class="md-layout-item md-xlarge-size-50 md-large-size-50 md-size-50 md-small-size-80 md-xsmall-size-90" novalidate
              @submit.prevent="validateInputs">
            <md-card>
                <md-card-header>
                    <div class="md-title">
                        Passwort vergessen
                    </div>
                </md-card-header>

                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field :class="getValidationClass('username')">
                                <label for="email">Email</label>
                                <md-input id="email" v-model="form.username" :disabled="sending" autocomplete="email" name="email"
                                          type="email"/>
                                <span v-if="!this.$v.form.username.required"
                                      class="md-error">
                                        Es muss eine Mailadresse angegeben werden!
                                    </span>
                                <span v-else-if="!this.$v.form.username.email" class="md-error">
                                        Diese Mailadresse ist ungültig!
                                    </span>
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
                <md-card-actions>
                    <md-button :disabled="sending" class="md-accent md-raised" v-on:click="goBack()">Abbrechen</md-button>
                    <md-button :disabled="sending" class="md-primary md-raised" type="submit">Mail anfordern</md-button>
                </md-card-actions>
            </md-card>
        </form>

        <md-card v-if="mode === MODES.SUCCESS"
                 class="md-layout-item md-xlarge-size-50 md-large-size-50 md-size-50 md-small-size-50 md-xsmall-size-90">
            <md-card-header>
                <div class="md-title">
                    Passwort vergessen
                </div>
            </md-card-header>

            <md-card-content>
                Die Mail an {{ form.username }} wurde verschickt.
                <br>
                Folge bitte den Anweisungen in der Mail um dein Passwort zurückzusetzen.
            </md-card-content>
        </md-card>

        <md-card v-if="mode === MODES.FAILED"
                 class="md-layout-item md-xlarge-size-50 md-large-size-50 md-size-50 md-small-size-50 md-xsmall-size-90">
            <md-card-header>
                <div class="md-title">
                    Fehler
                </div>
            </md-card-header>

            <md-card-content>
                Leider ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
import {email, required} from 'vuelidate/lib/validators'


const MODES = {
    FORM: 0,
    SUCCESS: 1,
    FAILED: 9
}

export default {
    name: 'All_PasswordReset',
    data: () => ({
        mode: 0,
        form: {
            username: '',
        },
        MODES,
    }),
    mounted() {
    },
    computed: {
        sending: function () {
            return this.$store.state.rxtx > 0
        }
    },
    methods: {
        goBack() {
            this.$router.back()
        },
        getValidationClass(fieldName) {
            const field = this.$v.form[fieldName];

            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
        validateInputs() {
            this.$v.form.$touch();
            if (!this.$v.$invalid) {
                const username = this.form.username
                this.$store.dispatch("requestPasswordResetMail", username).then(() => {
                    this.$v.$reset();
                    this.mode = MODES.SUCCESS
                }).catch(() => {
                    this.mode = MODES.FAILED
                    this.$store.dispatch("showSnackbar", {text: "Es ist ein Fehler aufgetreten."})
                })
            } else {
                // do nothing, user feedback will be displayed
            }
        },
    },
    validations() {
        return {
            form: {
                username: {
                    required,
                    email
                },
            }
        }
    },
}
</script>

<style lang="scss" scoped>
#container {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
}
</style>
